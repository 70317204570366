import { useEffect, useState } from "react";
import ReviewsFilters from "../components/reviews/filters";
import ReviewComponent from "../components/reviews/review";
import Separator from "../components/reviews/separator";
import { Review } from "../types";
import axios from "axios";
import { BACKEND_URL } from "../config";
import { useUser } from "../global_state";
import Spinner from "../components/spinner";

const ReviewsPage = () => {
  const [reviews, setReviews] = useState([] as Review[]);
  const [user, setUser] = useUser();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchReviews = async () => {
    setLoading(true);
    const pageSize = 5;
    if (user) {
      axios
        .get(BACKEND_URL + "/api/reviews/?page=" + page)
        .then((response) => {
          setReviews(response.data.results);
          setTotalPages(Math.floor(response.data.count / pageSize) + 1);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!loading) {
      fetchReviews();
    }
  }, [user]);

  useEffect(() => {
    if (!loading) {
      fetchReviews();
    }
  }, [page]);

  const EmptyListSvg = () => {
    return (
      <svg
        width="100"
        height="100"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="rgb(107 114 128)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="centered"
      >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        <line x1="8" y1="8" x2="14" y2="14"></line>
      </svg>
    );
  };

  return (
    <div className="grid gap-6">
      {/* <ReviewsFilters page={page} totalPages={totalPages} onChange={setPage} />
      <Separator /> */}
      {loading && <Spinner />}
      {!loading && reviews?.length === 0 && (
        <>
          <div className="flex justify-center">
            <EmptyListSvg />
          </div>
          <p className="text-center">No reviews found</p>
        </>
      )}
      {!loading &&
        reviews?.map((review) => (
          <ReviewComponent review={review} onChange={fetchReviews} />
        ))}
      <Separator />
      <ReviewsFilters page={page} totalPages={totalPages} onChange={setPage} />
    </div>
  );
};
export default ReviewsPage;
