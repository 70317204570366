import { useState } from "react";
import { Website } from "../../types";
import DeleteWebsiteDialog from "./delete_website_dialog";
import EditWebsiteDialog from "./edit_website_dialog";
import { timeSince } from "../../utils";
import { Link } from "react-router-dom";

type WebsiteCardProps = {
  website: Website;
  onChange: Function;
};

const WebsiteCard = ({ website, onChange }: WebsiteCardProps) => {
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);

  return (
    <>
      <div
        className="rounded-lg border bg-card text-card-foreground shadow-sm"
        data-v0-t="card"
      >
        <div className="flex flex-col space-y-1.5 p-6">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold">{website.name}</h2>
            <div className="text-gray-500 dark:text-gray-400 text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="w-4 h-4 mr-1 inline"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20"></path>
                <path d="M2 12h20"></path>
              </svg>
              {website.domains[0]}
              {website.domains.length > 1 && (
                <span className="ml-2 text-gray-500 dark:text-gray-400 text-sm">
                  +{website.domains.length - 1}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="p-6 space-y-2">
          <div className="flex items-center justify-between">
            <div className="text-gray-500 dark:text-gray-400 text-sm">
              <Link to={"/app/reviews/"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="w-4 h-4 mr-1 inline fill-primary"
                >
                  <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z"></path>
                </svg>
                {website.total_reviews_count} reviews
              </Link>
            </div>
            <div className="text-gray-500 dark:text-gray-400 text-sm">
              <Link to={"/app/reviews/"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="w-4 h-4 mr-1 inline"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <polyline points="12 6 12 12 16 14"></polyline>
                </svg>
                {website.pending_reviews_count} pending
              </Link>
            </div>
          </div>
          <div className="text-gray-500 dark:text-gray-400 text-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="w-4 h-4 mr-1 inline"
            >
              <path d="M8 2v4"></path>
              <path d="M16 2v4"></path>
              <rect width="18" height="18" x="3" y="4" rx="2"></rect>
              <path d="M3 10h18"></path>
              <path d="M8 14h.01"></path>
              <path d="M12 14h.01"></path>
              <path d="M16 14h.01"></path>
              <path d="M8 18h.01"></path>
              <path d="M12 18h.01"></path>
              <path d="M16 18h.01"></path>
            </svg>
            {website.last_event_date
              ? "Last event " +
                timeSince(new Date(website.last_event_date)) +
                " ago"
              : "No events yet"}
          </div>
          <div className="flex gap-2 justify-end">
            <button
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 w-10"
              onClick={() => setEditing(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="w-4 h-4"
              >
                <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path>
                <path d="m15 5 4 4"></path>
              </svg>
              <span className="sr-only">Edit</span>
            </button>
            <button
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 w-10 text-red-500"
              onClick={() => setDeleting(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="w-4 h-4"
              >
                <path d="M3 6h18"></path>
                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
              </svg>
              <span className="sr-only">Delete</span>
            </button>
          </div>
        </div>
      </div>
      <DeleteWebsiteDialog
        open={deleting}
        onClose={() => setDeleting(false)}
        website={website}
        onDelete={() => onChange()}
      />
      <EditWebsiteDialog
        open={editing}
        onClose={() => setEditing(false)}
        website={website}
        onEdit={() => onChange()}
      />
    </>
  );
};

export default WebsiteCard;
