import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import HeroImage from "../../assets/img/hero-image.png";

const HeroSection = () => {
  const allTopics = [
    "Urban Beekeping",
    "Vintage Typewriters",
    "Tiny House Design",
    "Bonsai Cultivation",
    "Sourdough Bread",
    "Hydroponic Gardening",
    "Lichenology Studies",
  ];
  const [topic, setTopic] = useState(allTopics[0]);

  let intervalId: NodeJS.Timeout;

  useEffect(() => {
    intervalId = setInterval(() => {
      setTopic(allTopics[(allTopics.indexOf(topic) + 1) % allTopics.length]);
    }, 1000);

    return function cleanup() {
      console.log("cleaning up");
      clearInterval(intervalId);
    };
  });

  return (
    <section
      className="w-full py-12 pt-24 sm:pt-24 md:py-24 lg:py-32 xl:py-48 flex justify-center"
      id="hero"
    >
      <div className="container px-4 md:px-6">
        <div className="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]">
          <div className="flex flex-col justify-center space-y-4">
            <div className="space-y-2">
              <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none">
                AI generated newsletters about&nbsp;
                <span className="inline-block text-primary">{topic}</span>
              </h1>
              <p className="max-w-[600px] text-muted-foreground md:text-xl">
                Generate AI-powered newsletters for your niche topics, save time
                browsing the web and stay ahead of the curve.
              </p>
            </div>
            <div className="flex flex-col gap-2 min-[400px]:flex-row">
              <a
                className="inline-flex h-10 items-center justify-center rounded-md bg-primary px-8 text-sm font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                href="#newsletter"
              >
                Sign Up
              </a>
            </div>
          </div>
          <img
            src={HeroImage}
            width="650"
            height="450"
            alt="Hero"
            className="mx-auto aspect-[16/9] overflow-hidden rounded-xl object-cover object-center sm:w-full"
          />
          {/* <div className="aspect-video overflow-hidden rounded-xl">
            <ReactPlayer url="https://www.youtube.com/watch?v=oocAAagAjQI" />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
