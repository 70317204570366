const PageButton = ({
  page,
  selected,
  onClick,
}: {
  page: number;
  selected: boolean;
  onClick: Function;
}) => {
  const selectedExtraClasses = " border border-input bg-background";
  return (
    <li className="">
      <li className="">
        <button
          className={
            "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10" +
            (selected ? selectedExtraClasses : "")
          }
          onClick={() => onClick(page)}
          rel="ugc"
          disabled={selected}
        >
          {page}
        </button>
      </li>
    </li>
  );
};

const NextButton = ({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: Function;
}) => {
  return (
    <li className="">
      <li className="">
        <button
          className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 gap-1 pr-2.5"
          aria-label="Go to next page"
          rel="ugc"
          onClick={() => onClick()}
          disabled={disabled}
        >
          <span>Next</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="h-4 w-4"
          >
            <path d="m9 18 6-6-6-6"></path>
          </svg>
        </button>
      </li>
    </li>
  );
};

const PreviousButton = ({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: Function;
}) => {
  return (
    <li className="">
      <li className="">
        <button
          className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 gap-1 pl-2.5"
          aria-label="Go to previous page"
          onClick={() => onClick()}
          rel="ugc"
          disabled={disabled}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="h-4 w-4"
          >
            <path d="m15 18-6-6 6-6"></path>
          </svg>
          <span>Previous</span>
        </button>
      </li>
    </li>
  );
};

const PageSelector = ({
  selectedPage,
  totalPages,
  onChange,
}: {
  selectedPage: number;
  totalPages: number;
  onChange: Function;
}) => {
  return (
    <nav
      aria-label="pagination"
      className="mx-auto flex w-full justify-center"
      role="navigation"
    >
      <ul className="flex flex-row items-center gap-1">
        <PreviousButton
          disabled={selectedPage === 1}
          onClick={() => onChange(selectedPage - 1)}
        />
        {[...Array(totalPages)].map((_, i) => (
          <PageButton
            key={i}
            page={i + 1}
            selected={i + 1 === selectedPage}
            onClick={onChange}
          />
        ))}
        <NextButton
          disabled={selectedPage === totalPages}
          onClick={() => onChange(selectedPage + 1)}
        />
      </ul>
    </nav>
  );
};

export default PageSelector;
