import { useEffect, useState } from "react";
import axios from "axios";
import { BACKEND_URL } from "../config";
import { Website } from "../types";
import WebsiteCard from "../components/websites/website_card";
import CreateWebsiteDialog from "../components/websites/create_website_dialog";
import { useUser } from "../global_state";
import Spinner from "../components/spinner";

const WebsitesPage = () => {
  const [websites, setWebsites] = useState<null | Website[]>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [user, setUser] = useUser();
  const [loading, setLoading] = useState(true);

  const fetchWebsites = async () => {
    await axios.get(BACKEND_URL + "/api/websites/").then(
      (response) => {
        setWebsites(response["data"]["results"]);
      },
      (error) => {
        console.log(error);
      }
    );
    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      fetchWebsites();
    }
  }, [user]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {websites?.map((website) => (
            <WebsiteCard
              key={website["id"]}
              website={website}
              onChange={fetchWebsites}
            />
          ))}
          {websites?.length === 0 && (
            <>
              <div
                className="rounded-lg border bg-card text-card-foreground shadow-sm"
                data-v0-t="card"
              >
                <div className="flex flex-col space-y-1.5 p-6">
                  <div className="flex items-center justify-between"></div>
                </div>
                <div className="flex items-center justify-center p-6">
                  <button
                    className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2"
                    onClick={() => setDialogOpen(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="w-4 h-4 mr-2"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5v14"></path>
                    </svg>
                    Add Website
                  </button>
                </div>
              </div>
              <CreateWebsiteDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onCreate={() => fetchWebsites()}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default WebsitesPage;
