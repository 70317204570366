const Separator = () => {
  return (
    <div
      data-orientation="horizontal"
      role="none"
      className="shrink-0 bg-gray-100 h-[1px] w-full"
    ></div>
  );
};
export default Separator;
