import { Link } from "react-router-dom";

const SubscribeSuccessPage = ({ sessionId }: { sessionId: string }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen gap-6">
      <div className="bg-green-100 p-4 rounded-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="w-12 h-12 text-green-500"
        >
          <path d="M20 6 9 17l-5-5"></path>
        </svg>
      </div>
      <div className="text-center space-y-2">
        <h2 className="text-3xl font-bold">Congratulations!</h2>
        <p className="text-gray-500 dark:text-gray-400">
          Your subscription is now active.
        </p>
      </div>
      <a
        className="inline-flex h-10 items-center justify-center rounded-md bg-gray-900 px-8 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50  bg-gradient-to-r from-[rgb(255,111,72)] to-[rgb(240,42,166)] text-gray-50 hover:opacity-90 curson:pointer"
        href="/login/"
      >
        Go to Dashboard
      </a>
    </div>
  );
};

export default SubscribeSuccessPage;
