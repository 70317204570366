import { useState } from "react";
import Switch from "../switch";
import PricingCard from "../pricing/pricing_card";

const PricingSection = ({ isLanding = false }: { isLanding?: boolean }) => {
  const [isYearly, setIsYearly] = useState(true);

  return (
    <section
      className="w-full py-12 md:py-24 lg:py-32 flex justify-center"
      id="pricing"
    >
      <div className="container px-4 md:px-6">
        {isLanding ? (
          <div className="space-y-4">
            <div className="inline-block rounded-lg bg-gray-100 px-3 py-1 text-sm">
              Pricing
            </div>
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
              Flexible Pricing to Fit Your Needs
            </h2>
            <p className="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              Choose the plan that works best for your business. <br />
              All plans come with a{" "}
              <span className="font-bold" style={{ color: "#f64882" }}>
                30-day free trial
              </span>
              .
            </p>
          </div>
        ) : (
          <div className="space-y-4">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
              Select your plan
            </h2>
            <p className="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              Choose the plan that works best for your business. <br />
              All plans come with a{" "}
              <span className="font-bold">30-day free trial</span>
            </p>
          </div>
        )}

        <div className="flex items-center justify-center gap-4">
          <span className="text-sm font-medium">Monthly</span>
          <Switch checked={isYearly} onChange={setIsYearly} />
          <span className="text-sm font-medium">Yearly</span>
        </div>
        <div className="mt-8 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          <PricingCard
            name="Starter"
            price={15}
            features={["1 website", "500 reviews per month", "Basic analytics"]}
            isYearly={isYearly}
            triggerStripe={!isLanding}
          />
          <PricingCard
            name="Pro"
            price={30}
            features={[
              "3 websites",
              "2000 reviews per month",
              "Advanced analytics",
            ]}
            isYearly={isYearly}
            triggerStripe={!isLanding}
            disabled={true}
          />
          <PricingCard
            name="Enterprise"
            price={60}
            features={[
              "5 websites",
              "10000 reviews per month",
              "Advanced analytics",
              "Connect with Jira, Slack, or Github",
            ]}
            isYearly={isYearly}
            triggerStripe={!isLanding}
            disabled={true}
          />
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
