import axios from "axios";
import { useState } from "react";
import { BACKEND_URL } from "../../config";
import Dialog from "../dialog";
import { toast } from "react-toastify";

type CreateWebsiteDialogProps = {
  open: boolean;
  onClose: Function;
  onCreate: Function;
};

const CreateWebsiteDialog = ({
  open,
  onClose,
  onCreate,
}: CreateWebsiteDialogProps) => {
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({
    name: null,
    domains: null,
  });
  const [domains, setDomains] = useState<string[]>([]);
  const onSubmit = async () => {
    axios
      .post(BACKEND_URL + "/api/websites/", {
        name: name,
        domains: domains,
      })
      .then((response) => {
        console.log(response.data);
        onClose();
        onCreate();
        setName("");
        setDomains([]);
        toast("Website created", {
          type: "success",
          position: "bottom-right",
        });
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setErrors(error.response.data);
        }
      });
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <div
        className="flex flex-col space-y-1.5 text-center sm:text-left"
        data-id="9"
      >
        <h2
          id="radix-:R9lafnnjaH1:"
          className="whitespace-nowrap text-lg font-semibold leading-none tracking-tight"
          data-id="10"
        >
          Add a New Website
        </h2>
        <p
          id="radix-:R9lafnnjaH2:"
          className="text-sm text-muted-foreground"
          data-id="11"
        >
          Fill out the form to add a new website to your list.
        </p>
      </div>
      <form className="grid gap-4 py-4" data-id="12" onSubmit={onSubmit}>
        <div className="grid gap-2" data-id="13">
          <label
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            data-id="14"
          >
            Name
          </label>
          <input
            className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
            id="name"
            placeholder="Enter website name"
            data-id="15"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          {errors.name && (
            <p className="text-red-500 text-sm" data-id="16">
              {errors.name}
            </p>
          )}
        </div>
        <div className="grid gap-2" data-id="16">
          <label
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            data-id="17"
          >
            Domains
          </label>
          <textarea
            className="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 min-h-[100px]"
            id="domains"
            placeholder="Enter domains separated by commas"
            data-id="18"
            onChange={(e) => setDomains(e.target.value.split(","))}
            value={domains.join(",")}
          ></textarea>
          {errors.domains && (
            <p className="text-red-500 text-sm" data-id="16">
              {errors.domains}
            </p>
          )}
        </div>
      </form>
      <div
        className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2"
        data-id="19"
      >
        <button
          className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 bg-gradient-to-r from-[rgb(255,111,72)] to-[rgb(240,42,166)] text-gray-50 hover:opacity-90"
          type="submit"
          data-id="20"
          onClick={onSubmit}
        >
          Save
        </button>
        <div data-id="21">
          <button
            className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2"
            data-id="22"
            onClick={() => onClose()}
          >
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateWebsiteDialog;
