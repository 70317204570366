import Header from "../components/landing/header";
import HeroSection from "../components/landing/hero_section";
import ContactSection from "../components/landing/contact_section";
import FeaturesSection from "../components/landing/features_section";
import Newsletter from "../components/landing/newsletter";

const LandingPage = () => {
  return (
    <>
      <Header />
      <div className="flex flex-col min-h-[100dvh]">
        <HeroSection />
        <FeaturesSection />
        <Newsletter />
        <ContactSection />
      </div>
    </>
  );
};

export default LandingPage;
