import React, { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import { BACKEND_URL } from "./config";
import AppRoutes from "./routes";
import SideNav from "./components/sidenav";
import Navbar from "./components/navbar";
import { getCookie } from "./utils";
import { useLocation } from "react-router-dom";
import { useUser } from "./global_state";
import { usePostHog } from "posthog-js/react";

function App() {
  const location = useLocation();
  const [user, setUser] = useUser();
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("auth_token")
  );

  const posthog = usePostHog();

  const fetchUserInfo = async () => {
    axios.get(BACKEND_URL + "/api/users/me/").then(
      (response) => {
        setUser(response["data"]);
        posthog?.identify(response["data"].id, {
          email: response["data"].email,
        });
        posthog?.capture("User logged in", {
          email: response["data"].email,
        });
        localStorage.setItem(
          "has_subscription",
          response["data"].has_subscription
        );
        return response["data"];
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const setCSRFCookie = () => {
    axios.interceptors.request.use(function (config) {
      const cookie = getCookie("csrftoken");
      config.headers["X-CSRFToken"] = cookie;
      return config;
    });
  };

  useEffect(() => {
    setCSRFCookie();
    axios.defaults.headers.common["Content-Type"] = "application/json";
    if (authToken) {
      axios.defaults.headers.common["Authorization"] = `Token ${authToken}`;
      if (!user) {
        fetchUserInfo();
      }
    }
  }, [authToken]);

  const logout = () => {
    localStorage.clear();
    setAuthToken(null);
  };
  if (!location.pathname.startsWith("/app")) {
    return <AppRoutes />;
  }

  return (
    <div>
      <Navbar user={user} logout={logout} />
      <div className="grid min-h-screen w-full grid-cols-[240px_1fr] overflow-hidden">
        <div className="flex flex-col border-r bg-gray-100/40 p-4">
          <SideNav />
        </div>
        <div className="flex flex-col">
          <main className="flex-1 p-4 md:p-6">
            <AppRoutes />
          </main>
        </div>
      </div>
    </div>
  );
}

export default App;
