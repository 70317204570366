import { Link } from "react-router-dom";
import {
  BACKEND_URL,
  STRIPE_PRODUCT_BASIC_MONTHLY_KEY,
  STRIPE_PRODUCT_BASIC_YEARLY_KEY,
} from "../../config";
import { useUser } from "../../global_state";

const GetStartedButton = ({ disabled }: { disabled: boolean }) => {
  return (
    <Link to="/register">
      <button
        disabled={disabled}
        className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 w-full bg-gradient-to-r from-[rgb(255,111,72)] to-[rgb(240,42,166)] text-gray-50 hover:opacity-90"
      >
        Get Started
      </button>
    </Link>
  );
};

const PricingCard = ({
  name,
  price,
  features,
  isYearly,
  triggerStripe = false,
  disabled = false,
}: {
  name: string;
  price: number;
  features: string[];
  isYearly: boolean;
  triggerStripe?: boolean;
  disabled?: boolean;
}) => {
  const [user, setUser] = useUser();
  return (
    <div
      className="rounded-lg border bg-card text-card-foreground shadow-sm"
      data-v0-t="card"
    >
      <div className="flex flex-col space-y-1.5 p-6">
        <h3 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
          {name}
        </h3>
        <p className="text-sm text-muted-foreground">
          <span className="text-4xl font-bold">
            ${isYearly ? ((price * 2) / 3).toFixed(2) : price.toFixed(2)}
          </span>
          <span className="text-gray-500">
            /{isYearly ? "month (paid annually)" : "month"}
          </span>
          {disabled && (
            <div className="text-red-500 font-bold">COMING SOON!</div>
          )}
        </p>
      </div>
      <div className="p-6 space-y-2">
        {features.map((feature) => (
          <div className="flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="h-4 w-4 text-green-500"
            >
              <path d="M20 6 9 17l-5-5"></path>
            </svg>
            <span>{feature}</span>
          </div>
        ))}
      </div>
      <div className="flex items-center p-6">
        {triggerStripe ? (
          <>
            <form
              action={
                BACKEND_URL + "/api/payments/stripe/create-checkout-session/"
              }
              method="POST"
            >
              <input
                type="hidden"
                name="lookup_key"
                value={
                  isYearly
                    ? STRIPE_PRODUCT_BASIC_YEARLY_KEY
                    : STRIPE_PRODUCT_BASIC_MONTHLY_KEY
                }
              />
              <input type="hidden" name="email" value={user?.email || ""} />
              <button
                id="checkout-and-portal-button"
                type="submit"
                disabled={disabled}
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 w-full bg-gradient-to-r from-[rgb(255,111,72)] to-[rgb(240,42,166)] text-gray-50 hover:opacity-90"
              >
                Subscribe
              </button>
            </form>
          </>
        ) : (
          <GetStartedButton disabled={disabled} />
        )}
      </div>
    </div>
  );
};
export default PricingCard;
