import TailorImage from "../../assets/img/tailor.png";

const ContactSection = () => {
  return (
    <section
      className="w-full py-12 md:py-24 lg:py-32 flex justify-center bg-muted"
      id="contact"
    >
      <div className="container px-4 md:px-6">
        <div className="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]">
          <div className="flex flex-col justify-center space-y-4">
            <div className="inline-block rounded-lg px-3 py-1 text-sm">
              Contact
            </div>
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
              Get in Touch
            </h2>
            <p className="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              We'd love to hear your feedback! You can send it to us using the
              "Submit Feedback" tab, or email us directly at &nbsp;
              <a
                className="font-medium underline"
                href="mailto:contact@captureecho.com"
              >
                hello@tailormadenews.com
              </a>
              .{"\n                "}
            </p>
          </div>
          <img
            src={TailorImage}
            width="650"
            height="450"
            alt="Hero"
            className="mx-auto aspect-[16/9] overflow-hidden rounded-xl object-cover object-center sm:w-full"
          />
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
