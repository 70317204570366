import { supabase } from "../../services/supabase";
import { toast } from "react-toastify";

const Newsletter = () => {
  async function handleSubmit(e: any) {
    //function to be carried out when form is submitted
    e.preventDefault();
    const email = e.target.children[0].value;

    await supabase
      .from("subscribers")
      .insert([
        {
          email: email,
        },
      ])
      .then((res) => {
        toast("Thank you for subscribing!", {
          type: "success",
          position: "bottom-right",
        });
      });
  }

  return (
    <section
      className="w-full py-12 md:py-24 lg:py-32 flex justify-center"
      id="newsletter"
    >
      <div className="container grid items-center justify-center gap-4 px-4 text-center md:gap-8 md:px-6">
        <div className="space-y-4">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
            Subscribe to Tailor Made News
          </h2>
          <p className="max-w-[600px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
            Get the latest news, insights, and trends tailored just for you.
            <br />
            Sign up now and receive a special discount.
          </p>
        </div>
        <div className="mx-auto w-full max-w-sm space-y-2">
          <form className="flex gap-2" onSubmit={handleSubmit}>
            <input
              className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 max-w-lg flex-1"
              placeholder="Enter your email"
              type="email"
            />
            <button
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
              type="submit"
            >
              Subscribe
            </button>
          </form>
          <p className="text-xs text-muted-foreground">
            Sign up to get a special discount when we launch.
          </p>
        </div>
      </div>
    </section>
  );
};
export default Newsletter;
