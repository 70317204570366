import axios from "axios";
import { toast } from "react-toastify";
import { BACKEND_URL } from "../../config";
import Dialog from "../dialog";
import { Website } from "../../types";

type DeleteWebsiteDialogProps = {
  open: boolean;
  onClose: Function;
  onDelete: Function;
  website: Website;
};

const DeleteWebsiteDialog = ({
  open,
  onClose,
  onDelete,
  website,
}: DeleteWebsiteDialogProps) => {
  const onSubmit = async () => {
    axios
      .delete(BACKEND_URL + `/api/websites/${website.id}/`)
      .then((response) => {
        console.log(response.data);
        toast("Website deleted", {
          type: "success",
          position: "bottom-right",
        });
        onDelete();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <div
        className="flex flex-col space-y-1.5 text-center sm:text-left"
        data-id="9"
      >
        <h2
          id="radix-:R9lafnnjaH1:"
          className="whitespace-nowrap text-lg font-semibold leading-none tracking-tight"
          data-id="10"
        >
          Delete website
        </h2>
        <p
          id="radix-:R9lafnnjaH2:"
          className="text-sm text-muted-foreground"
          data-id="11"
        >
          Are you sure you want to delete the "{website.name}" website?
        </p>
      </div>
      <div
        className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2"
        data-id="19"
      >
        <button
          className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 bg-red-500 hover:bg-red-600 text-white"
          type="submit"
          data-id="20"
          onClick={onSubmit}
        >
          Delete
        </button>
        <div data-id="21">
          <button
            className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2"
            data-id="22"
            onClick={() => onClose()}
          >
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteWebsiteDialog;
