import axios from "axios";
import SvgGoogleLogo from "../assets/google_logo";
import { BACKEND_URL } from "../config";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { Link, Navigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const token = localStorage.getItem("auth_token");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    username: "",
    password: "",
    password2: "",
    non_field_errors: "",
  });
  let navigate = useNavigate();

  const logInWithAccessToken = async (
    accessToken: string,
    profile_info: any
  ) => {
    const response = await axios.post(
      BACKEND_URL + "/api/auth/google/login/",
      {
        access_token: accessToken,
        profile: profile_info,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const jwt_token = response.data;
    localStorage.clear();
    localStorage.setItem("auth_token", jwt_token);
    window.location.href = "/app/websites/";
    return jwt_token;
  };

  const onSuccess = (
    tokenResponse: Omit<
      TokenResponse,
      "error" | "error_description" | "error_uri"
    >
  ) => {
    setLoading(true);
    checkTokenInfo(tokenResponse);
  };

  const onError = (error: any) => {
    console.log("login failed:", error);
  };

  const login = useGoogleLogin({
    onSuccess,
    onError,
  });

  if (token) {
    return <Navigate to="/app/websites/" />;
  }

  const onFormSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    await axios
      .post(BACKEND_URL + "/api/auth/register/", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        toast(
          "Your registration was processed correctly. You may now log in.",
          {
            type: "success",
            position: "bottom-right",
          }
        );
        navigate("/login/");
      })
      .catch((error) => {
        setErrors(error.response.data);
        console.log(error);
      });
  };

  const checkTokenInfo = async (tokenInfo: TokenResponse) => {
    if (tokenInfo) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${tokenInfo["access_token"]}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenInfo["access_token"]}`,
            },
          }
        )
        .then(
          async (response) => {
            const newAuthToken = await logInWithAccessToken(
              tokenInfo["access_token"],
              response["data"]
            );
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-[100dvh] items-center justify-center bg-gray-100 px-4">
      <div className="mx-auto max-w-md w-full space-y-6">
        <div className="text-center">
          <h1 className="text-3xl font-bold tracking-tight">
            Register your account
          </h1>
          <p className="mt-2 text-gray-500">
            Already have an account?{" "}
            <Link
              className="font-medium text-gray-900 hover:underline"
              to="/login"
            >
              Log In
            </Link>
          </p>
        </div>
        <div className="space-y-4">
          <button
            className="login-button inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary hover:bg-primary/90 h-10 px-4 py-2 w-full hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
            onClick={() => login()}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{ height: 18, marginRight: 8, minWidth: 18, width: 18 }}
              >
                <SvgGoogleLogo width={20} height={20} />
              </div>
              <div
                style={{
                  lineHeight: "18px",
                  fontFamily: '"Google Sans", arial, sans-serif',
                  fontWeight: 500,
                }}
              >
                Sign up with Google
              </div>
            </div>
          </button>
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="bg-gray-100 px-2 text-gray-500">
                Or continue with
              </span>
            </div>
          </div>
          <form className="space-y-4" onSubmit={onFormSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                  First Name
                </label>
                <input
                  className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                  id="first_name"
                  name="first_name"
                  placeholder="John"
                />
                {errors.first_name && (
                  <p className="text-red-500 ">{errors.first_name}</p>
                )}
              </div>
              <div>
                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                  Last Name
                </label>
                <input
                  className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                  id="last_name"
                  name="last_name"
                  placeholder="Doe"
                />
                {errors.last_name && (
                  <p className="text-red-500 ">{errors.last_name}</p>
                )}
              </div>
            </div>
            <div>
              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                Email address
              </label>
              <input
                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                id="email"
                name="username"
                placeholder="name@example.com"
                type="email"
              />
              {errors.username && (
                <p className="text-red-500 ">{errors.username}</p>
              )}
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="flex items-center justify-between">
                  <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    Password
                  </label>
                </div>
                <input
                  className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                  id="password"
                  placeholder="••••••••"
                  type="password"
                  name="password"
                />
                {errors.password && (
                  <p className="text-red-500 ">{errors.password}</p>
                )}
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    Repeat Password
                  </label>
                </div>
                <input
                  className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                  id="password2"
                  placeholder="••••••••"
                  name="password2"
                  type="password"
                />
                {errors.password2 && (
                  <p className="text-red-500 ">{errors.password2}</p>
                )}
              </div>
            </div>
            <button
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary hover:bg-primary/90 h-10 px-4 py-2 w-full bg-gradient-to-r from-[rgb(255,111,72)] to-[rgb(240,42,166)] text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
              type="submit"
              disabled={loading}
            >
              Register
            </button>
            <Link to="/">
              <button
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary hover:bg-primary/90 h-10 px-4 py-2 w-full text-black hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
                type="submit"
                disabled={loading}
              >
                Back
              </button>
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
