import React from "react";
import { User } from "../types";
import { Link } from "react-router-dom";

import Logo from "../assets/img/logo.png";

type Props = {
  user: User | null;
  logout: () => void;
};

const UserMenu = ({ logout, user }: Props) => {
  return (
    <div
      data-radix-popper-content-wrapper=""
      dir="ltr"
      style={{
        position: "fixed",
        right: 25,
        top: 48,
        minWidth: "max-content",
        transformOrigin: "100% 0px",
        zIndex: 50,
      }}
      className="bg-gray-100"
    >
      <div
        data-side="bottom"
        data-align="end"
        role="menu"
        aria-orientation="vertical"
        data-state="open"
        data-radix-menu-content=""
        dir="ltr"
        id="radix-:rr:"
        aria-labelledby="radix-:rq:"
        className="z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
        data-id="33"
        data-orientation="vertical"
        style={{
          outline: "none",
        }} //"outline: none; --radix-dropdown-menu-content-transform-origin: var(--radix-popper-transform-origin); --radix-dropdown-menu-content-available-width: var(--radix-popper-available-width); --radix-dropdown-menu-content-available-height: var(--radix-popper-available-height); --radix-dropdown-menu-trigger-width: var(--radix-popper-anchor-width); --radix-dropdown-menu-trigger-height: var(--radix-popper-anchor-height); pointer-events: auto;"
      >
        <div className="px-2 py-1.5 text-sm font-semibold" data-id="34">
          {user?.first_name + " " + user?.last_name}
        </div>
        <div
          role="separator"
          aria-orientation="horizontal"
          className="-mx-1 my-1 h-px bg-muted"
          data-id="35"
        ></div>
        {/* 
        <div
          role="menuitem"
          className="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
          data-id="36"
          data-orientation="vertical"
          data-radix-collection-item=""
        >
          Settings
        </div>
        <div
          role="menuitem"
          className="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
          data-id="37"
          data-orientation="vertical"
          data-radix-collection-item=""
        >
          Support
        </div>
        <div
          role="separator"
          aria-orientation="horizontal"
          className="-mx-1 my-1 h-px bg-muted"
          data-id="38"
        ></div> */}
        <div
          role="menuitem"
          className="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 cursor-pointer"
          data-id="37"
          data-orientation="vertical"
          data-radix-collection-item=""
          onClick={() => (window.location.href = "/login/")}
        >
          Dashboard
        </div>
        <div
          role="menuitem"
          className="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 cursor-pointer"
          data-id="39"
          data-orientation="vertical"
          data-radix-collection-item=""
          onClick={() => logout()}
        >
          Logout
        </div>
      </div>
    </div>
  );
};

export const UserButton = ({ user, logout }: Props) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  return (
    <>
      <button
        className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10 rounded-full"
        type="button"
        id="radix-:ri:"
        aria-haspopup="menu"
        aria-expanded="false"
        data-state="closed"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <img
          src={
            "https://ui-avatars.com/api/?background=random&name=" +
            user?.first_name +
            "+" +
            user?.last_name
          }
          width="32"
          height="32"
          className="rounded-full"
          alt="Avatar"
          style={{ aspectRatio: "32 / 32", objectFit: "cover" }}
        />
        <span className="sr-only">Toggle user menu</span>
      </button>

      {dropdownOpen && <UserMenu user={user} logout={logout} />}
    </>
  );
};

function Navbar({ user, logout }: Props) {
  return (
    <>
      <header className="flex h-14 items-center gap-4 border-b bg-gray-100/40 px-6">
        <Link className="lg:hidden" to="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="h-6 w-6"
          >
            <path d="M3 9h18v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z"></path>
            <path d="m3 9 2.45-4.9A2 2 0 0 1 7.24 3h9.52a2 2 0 0 1 1.8 1.1L21 9"></path>
            <path d="M12 3v6"></path>
          </svg>
          <span className="sr-only">Home</span>
        </Link>
        <div className="flex-1">
          <div className="flex h-[60px] items-center">
            <Link className="flex items-center gap-2 font-semibold" to="/">
              <img src={Logo} alt="Logo" width={24} />
              TailorMadeNews
            </Link>
          </div>
          {/* <h1 className="font-semibold text-lg">User Reviews</h1> */}
        </div>
        <div className="flex items-center gap-4">
          {/* <form className="flex-1">
            <div className="relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-500"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <path d="m21 21-4.3-4.3"></path>
              </svg>
              <input
                className="flex h-10 w-full rounded-md border border-input px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px] bg-white"
                placeholder="Search reviews..."
                type="search"
              />
            </div>
          </form> */}
          <UserButton user={user} logout={logout} />
        </div>
      </header>
    </>
  );
}

export default Navbar;
