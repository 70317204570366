import { useState } from "react";
import { Review } from "../../types";
import { timeSince } from "../../utils";
import Dialog from "../dialog";
import { BACKEND_URL } from "../../config";
import axios from "axios";

type ReviewProps = {
  review: Review;
  onChange: Function;
};

const ReviewComponent = ({ review, onChange }: ReviewProps) => {
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [completedDialogOpen, setCompletedDialogOpen] = useState(false);
  const [dismissedDialogOpen, setDismissedDialogOpen] = useState(false);

  const updateStatus = async (newStatus: string) => {
    const response = await axios.patch(
      `${BACKEND_URL}/api/reviews/${review.id}/`,
      {
        status: newStatus,
      }
    );
    onChange();
  };

  return (
    <>
      <div className="flex gap-4">
        <span className="relative flex shrink-0 overflow-hidden rounded-full w-10 h-10 border">
          <img
            className="aspect-square h-full w-full"
            alt="@shadcn"
            src={
              "https://ui-avatars.com/api/?background=random&name=" +
              review.reviewer.name
            }
          />
        </span>
        <div className="grid gap-4">
          <div className="flex gap-4 items-start">
            <div className="grid gap-0.5 text-sm">
              <h3 className="font-semibold">{review.reviewer.name}</h3>
              <time className="text-sm text-gray-500 dark:text-gray-400">
                {review.updated_at
                  ? `${timeSince(new Date(review.updated_at))} ago`
                  : ""}
              </time>
            </div>
          </div>
          <div className="text-sm leading-loose bg-gray-100 dark:bg-gray-800 p-4 rounded-lg text-gray-900 dark:text-gray-50">
            <p className={review.screenshots?.length ? "mb-4" : ""}>
              {review.content}
            </p>
            {review.screenshots?.map((screenshot) => (
              <img
                src={screenshot.image}
                alt="Review image"
                width="600"
                height="400"
                className="aspect-video rounded-lg object-cover border cursor-pointer"
                onClick={() => setImageDialogOpen(true)}
              />
            ))}
          </div>
          <div className="flex gap-2">
            <button
              className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary hover:bg-primary/90 h-9 rounded-md px-3 bg-gradient-to-r from-[#FF6F48] to-[#F02AA6] text-white"
              onClick={() => setCompletedDialogOpen(true)}
            >
              Mark as Completed
            </button>
            <button
              className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-9 rounded-md px-3"
              onClick={() => setDismissedDialogOpen(true)}
            >
              Dismiss
            </button>
          </div>
        </div>
      </div>
      <Dialog open={imageDialogOpen} onClose={() => setImageDialogOpen(false)}>
        {review.screenshots?.map((screenshot) => (
          <img
            src={screenshot.image}
            alt="Review image"
            className="max-w-[80vw] max-h-[80vh] mx-auto min-w-[50vw] cursor-pointer"
          />
        ))}
      </Dialog>
      <Dialog
        open={completedDialogOpen}
        onClose={() => setCompletedDialogOpen(false)}
      >
        <>
          <div
            className="flex flex-col space-y-1.5 text-center sm:text-left"
            data-id="9"
          >
            <h2
              id="radix-:R9lafnnjaH1:"
              className="whitespace-nowrap text-lg font-semibold leading-none tracking-tight"
              data-id="10"
            >
              Mark this review as completed
            </h2>
            <p
              id="radix-:R9lafnnjaH2:"
              className="text-sm text-muted-foreground pb-4"
              data-id="11"
            >
              Are you sure you want to mark this review as completed?
            </p>
          </div>
          <div
            className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2"
            data-id="19"
          >
            <button
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 bg-gradient-to-r from-[rgb(255,111,72)] to-[rgb(240,42,166)] text-gray-50 hover:opacity-90"
              type="submit"
              data-id="20"
              onClick={() => {
                updateStatus("Completed");
                setCompletedDialogOpen(false);
              }}
            >
              Confirm
            </button>
            <div data-id="21">
              <button
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2"
                data-id="22"
                onClick={() => setCompletedDialogOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      </Dialog>
      <Dialog
        open={dismissedDialogOpen}
        onClose={() => setDismissedDialogOpen(false)}
      >
        <>
          <div
            className="flex flex-col space-y-1.5 text-center sm:text-left"
            data-id="9"
          >
            <h2
              id="radix-:R9lafnnjaH1:"
              className="whitespace-nowrap text-lg font-semibold leading-none tracking-tight"
              data-id="10"
            >
              Mark this review as dismissed
            </h2>
            <p
              id="radix-:R9lafnnjaH2:"
              className="text-sm text-muted-foreground pb-4"
              data-id="11"
            >
              Are you sure you want to mark this review as dismissed?
            </p>
          </div>
          <div
            className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2"
            data-id="19"
          >
            <button
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 bg-gradient-to-r from-[rgb(255,111,72)] to-[rgb(240,42,166)] text-gray-50 hover:opacity-90"
              type="submit"
              data-id="20"
              onClick={() => {
                updateStatus("Dismissed");
                setDismissedDialogOpen(false);
              }}
            >
              Confirm
            </button>
            <div data-id="21">
              <button
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2"
                data-id="22"
                onClick={() => setDismissedDialogOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      </Dialog>
    </>
  );
};
export default ReviewComponent;
