import React, { useState, useEffect } from "react";
import PricingSection from "../components/landing/pricing_section";
import Navbar from "../components/navbar";
import { useUser } from "../global_state";
import SubscribeSuccessPage from "./subscribe_success";
import SubscribeErrorPage from "./subscribe_error";
import Spinner from "../components/spinner";

const ProductDisplay = () => {
  const [user, setUser] = useUser();

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  return (
    <>
      <Navbar user={user} logout={logout} />
      <div className="flex flex-col min-h-[100dvh]">
        {user ? <PricingSection isLanding={false} /> : <Spinner />}
      </div>
    </>
  );
};

export default function SubscribePage() {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");
  const [user, setUser] = useUser();

  useEffect(() => {
    if (user?.has_subscription) {
      window.location.href = "/app/websites/";
    }
  }, [user]);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      const sessionId = query.get("session_id");
      if (sessionId) {
        setSessionId(sessionId);
      }
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  if (!success && message === "") {
    return <ProductDisplay />;
  } else if (success && sessionId !== "") {
    return <SubscribeSuccessPage sessionId={sessionId} />;
  } else {
    return <SubscribeErrorPage />;
  }
}

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14px"
    height="16px"
    viewBox="0 0 14 16"
    version="1.1"
  >
    <defs />
    <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="0-Default"
        transform="translate(-121.000000, -40.000000)"
        fill="#E184DF"
      >
        <path
          d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
          id="Pilcrow"
        />
      </g>
    </g>
  </svg>
);
