export const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || "https://services.captureecho.com";
export const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  "613662376125-qo3c44sio7m4p0ogfk6t0k8nc1eec8h3.apps.googleusercontent.com";
export const STRIPE_PRODUCT_BASIC_YEARLY_KEY =
  process.env.REACT_APP_STRIPE_PRODUCT_BASIC_YEARLY_KEY || "basic_yearly";
export const STRIPE_PRODUCT_BASIC_MONTHLY_KEY =
  process.env.REACT_APP_STRIPE_PRODUCT_BASIC_MONTHLY_KEY || "basic_monthly";
export const PUBLIC_POSTHOG_KEY = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;
export const PUBLIC_POSTHOG_HOST =
  process.env.REACT_APP_PUBLIC_POSTHOG_HOST || "https://eu.i.posthog.com";
